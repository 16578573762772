<template>
  <el-container class="result" style="display:block">
    <!-- <el-header>医疗服务项目标化价值智能测算系统</el-header> -->
    <commheader />
    <el-main class="resuMain">
      <div class="resunew">
        <el-form class="resuForm">
          <el-button type="primary" class="resubtn" @click="resback"
            >上一步</el-button
          >
          <el-button type="primary" plain class="resubtn" @click="newevdata"
            >新建测评数据模型</el-button
          >
        </el-form>
        <!-- 第一步 -->
        <h3 class="resutit">第一步：新建测评数据模型</h3>
        <div>
          <el-table
            id="table"
            stripe
            :data="resuData"
            style="width: 80%; font-size: 15px"
            border
            class="resuTable"
          >
            <el-table-column
              type="index"
              width="55"
              label="序号"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="测评数据名称"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="createDate"
              label="创建时间"
              align="center"
              width="175"
            >
            </el-table-column>
            <el-table-column prop="" label="操作" align="center" width="180">
              <template style="display: flex" slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="resuEdit(scope.row)"
                  ><i class="el-icon-s-tools">设置</i></el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="removeEvaluations(scope.row)"
                  ><i class="el-icon-delete">删除</i></el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          class="resuPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          :page-sizes="[20]"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-main>
    <!-- 点击新建测评数据模型后的弹出框 -->
    <el-dialog
      title="新建测评数据"
      :visible.sync="dialognewevdata"
      width="400px"
      :before-close="dialognewevdataClose"
    >
      <el-form :model="newevForm" ref="newevFormRefs" :rules="newevFormRules">
        <el-form-item label="测评数据名称" prop="name" class="newevitem">
          <el-input v-model="newevForm.name"></el-input>
        </el-form-item>
        <el-form-item class="newevsub">
          <el-button type="primary" @click="saveEvaluations">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  data() {
    return {
      // 点击新建测评数据模型后的弹出框
      dialognewevdata: false,
      resuData: [],
      //   新建测评数据表单数据
      newevForm: { name: "" },
      newevFormRules: {
        name: [
          { required: true, message: "请输入测评数据名称", trigger: "blur" },
        ],
      },
      pageNum: 0, //当前页数
      pageSize: 20, //每页显示条数
      total: 0, //总数
    };
  },
  computed: {},
  created() {
    // 分页显示测评数据列表
    this.getEvaluation();
  },
  beforeDestroy() {},
  methods: {
    //   点击操作的修改跳转并携带参数id
    resuEdit(row) {
      // console.log(row.id);
      this.$router.push({
        path: "/rtwo",
        query: {
          eId: row.id,
        },
      });
    },
    // 点击操作的删除
    async removeEvaluations(row) {
      let data = _qs.stringify({
        id: row.id,
      });
      let { data: res } = await this.$axios.removeEvaluations(data);

      const confirmResult = await this.$confirm(
        "此操作将永久删除该文件, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (confirmResult !== "confirm") {
        return this.$message.info("已经取消删除");
      }

      if (res.code == 0) {
        // console.log(res);
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getEvaluation();
      } else if (res.code == 401) {
        this.$router.push("/login");
      }
    },
    // 点击上一步
    resback() {
      this.$router.push("/index");
    },
    // 新建测评数据模型
    newevdata() {
      this.dialognewevdata = true;
    },
    // 新建测评数据模型的确认按钮
    saveEvaluations() {
      this.$refs.newevFormRefs.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          name: this.newevForm.name,
        });
        let res = await this.$axios.saveEvaluations(data);
        // console.log(res);
        if (res.status == 200) {
          this.dialognewevdata = false;
          this.$message({
            message: "新建成功",
            type: "success",
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else if (res.data.code == 401) {
          this.$router.push("/login");
        }
      });
    },
    // 关闭新建测评数据
    dialognewevdataClose() {
      this.dialognewevdata = false;
      this.$refs.newevFormRefs.clearValidate();
    },
    // 每页显示多少条
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getEvaluation();
    },
    // 当前第几页
    handleCurrentChange(val) {
      //   console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getEvaluation();
    },
    // 分页显示测评数据列表
    async getEvaluation() {
      let data = {
        name: this.newevForm.name,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      let { data: res } = await this.$axios.getEvaluation(data);
      // console.log(res);
      if (res.code == 200) {
        this.total = res.total;
        this.resuData = res.rows;
      } else if (res.code == 401) {
        this.$router.push("/login");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.resunew {
  margin: 0 auto;
}

.resutit {
  text-align: center;
  margin-top: 44px;
}

.resuForm {
  text-align: center;
}

.resubtn {
  width: 170px;
  height: 60px;
  font-size: 16px;
  margin-left: 30px !important;
}

.resuMain {
  margin-top: 30px;
}

.resuTable {
  margin: 0 auto;
  margin-top: 40px;
  /deep/ .el-table__header-wrapper .el-table__header .has-gutter {
    color: black !important;
  }
}

.resuPage {
  text-align: center;
  margin-top: 20px;
}

.newevitem {
  display: flex;
  justify-content: center;
}

.newevsub {
  width: 70px;
  margin: 0 auto;
}
</style>
