<template>
  <el-container style="display: block">
    <!-- <el-header>医疗服务项目标化价值智能测算系统</el-header> -->
    <commheader />
    <el-main>
      <div class="nofee price">
        <el-form ref="form" class="bigform" :model="form" :inline="true">
          <el-button
            @click="excel"
            icon="el-icon-download"
            class="elbutton"
            type="primary"
            plain
            >下载Excel模版</el-button
          >
          <el-upload
            ref="uploadone"
            class="upload-demo"
            :headers="token"
            :action="downurl"
            :data="{ type: 0, evaluationsId: this.$route.query.eId,  }"
            :file-list="fileList"
            :limit="limitUpload"
            style="display: inline-block"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            :on-success="success"
            :on-progress="uploading"
          >
            <el-button type="primary" class="elbutton"
              >导入数据<i class="el-icon-upload el-icon--right"></i
            ></el-button>
          </el-upload>
          <!-- <el-button
            icon="el-icon-circle-plus-outline"
            @click="addshu"
            class="elbutton"
            type="primary"
            plain
            >添加数据</el-button
          > -->
          <el-button @click="backnonfee" class="elbutton" type="primary"
            >上一步</el-button
          >
          <el-button @click="oneBut" class="elbutton" type="primary"
            >下一步</el-button
          >
        </el-form>
        <h3 class="tabhea">第二步：导入添加基础数据</h3>
        <el-table
          id="table"
          stripe
          :data="tableData"
          style="width: 85%; font-size: 15px"
          border
          class="price-table"
        >
          <el-table-column prop="data0" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column
            prop="data1"
            label="项目编码"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column prop="data2" label="项目名称" align="center">
          </el-table-column>
          <el-table-column prop="data3" label="耗材" align="center" width="80">
          </el-table-column>
          <el-table-column prop="data4" label="医生" align="center" width="80">
          </el-table-column>
          <el-table-column prop="data5" label="护士" align="center" width="80">
          </el-table-column>
          <el-table-column prop="data6" label="技师" align="center" width="80">
          </el-table-column>
          <el-table-column prop="data7" label="工勤" align="center" width="80">
          </el-table-column>
          <el-table-column
            prop="data8"
            label="耗时(小时)"
            align="center"
            width="95"
          >
          </el-table-column>
          <el-table-column
            prop="data9"
            label="技术难度系数"
            align="center"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="data10"
            label="风险程度系数"
            align="center"
            width="120"
          >
          </el-table-column>
          <!-- <el-table-column prop="" label="操作" width="150" align="center">
            <template style="display: flex">
              <el-button size="mini" type="primary" @click="addshu"
                ><i class="el-icon-edit"></i
              ></el-button>
              <el-button size="mini" type="danger"
                ><i class="el-icon-delete-solid" @click="open"></i
              ></el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
    </el-main>
    <!-- 点击添加数据的弹出框 -->
    <el-dialog title="添加数据" :visible.sync="dialogVisible" width="30%">
      <el-form
        ref=""
        :model="add"
        :inline="true"
        class="add"
        label-width="100px"
      >
        <el-form-item label="项目编码：">
          <el-input v-model="add.code"></el-input>
        </el-form-item>
        <el-form-item label="项目名称：">
          <el-input v-model="add.name"></el-input>
        </el-form-item>
        <el-form-item label="耗材：">
          <el-input v-model="add.lowGoodsUseLevel"></el-input>
        </el-form-item>
        <el-form-item label="医生：">
          <el-input v-model="add.doctorNums"></el-input>
        </el-form-item>
        <el-form-item label="护士：">
          <el-input v-model="add.nursingNums"></el-input>
        </el-form-item>
        <el-form-item label="技师：">
          <el-input v-model="add.technicianNums"></el-input>
        </el-form-item>
        <el-form-item label="工勤：">
          <el-input v-model="add.workAttendanceNums"></el-input>
        </el-form-item>
        <el-form-item label="耗时：">
          <el-input v-model="add.averageValue"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addsub">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="正在上传中，请稍等~"
      :visible.sync="dialogVisibleloding"
      width="30%"
      :show-close="false"
      center
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
    </el-dialog>
  </el-container>
</template>

<script>
import Bus from "../../../config/Bus";
import _qs from "qs";
export default {
  name: "Upload",
  data() {
    return {
      downurl: this.GLOBAL + "/openApi/priceRatio/importExcel",
      // 添加数据
      add: {
        code: "", //项目编码
        name: "", //项目名称
        lowGoodsUseLevel: "", //耗材
        doctorNums: "", //医生
        nursingNums: "", //护士
        technicianNums: "", //技师
        workAttendanceNums: "", //工勤
        averageValue: "", //耗时
      },
      dialogVisible: false,
      form: {
        people: "1.0", //人员系数调整
        ratio: "1.0", //比价成本偏移
      },
      tableHead: [],
      fileList: [], // 上传文件的列表
      limitUpload: 1, //最大允许上传个数
      // 表格数据
      tableData: [],
      dialogVisibleloding: false, //正在上传中
    };
  },
  computed: {
    token() {
      return {
        Authorization: "Bearer " + window.sessionStorage.getItem("token"),
      };
    },
  },
  mounted() {},
  created() {
    Bus.$on("succe", (val) => {
      this.tableData = val;
      // console.log(val)
    });
    // 判断上一页是从首页跳转过来的还是测评结果跳转过来的
    // console.log(this.$route.query.toIndex)
    if (this.$route.query.toIndex !== "toIndex") {
      // 从测评结果跳转过来的
      this.importToResult();
    } else {
      // 从index跳转过来的
      return;
    }
  },
  beforeDestroy() {
    Bus.$off("succe");
  },
  methods: {
    // 返回上一页
    backnonfee() {
      this.$router.go(-1);
      let staff = JSON.parse(this.$route.query.staff);
      setTimeout(() => {
        Bus.$emit("staff", staff);
      }, 100);
    },
    // 点击删除按钮
    open() {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 点击添加数据
    addshu() {
      this.dialogVisible = true;
    },
    // 点击添加数据确认按钮
    addsub() {
      this.dialogVisible = false;
    },
    // 点击下一步
    oneBut() {
      if (this.tableData.length <= 0) {
        this.$alert("请导入基础数据！", {
          confirmButtonText: "确定",
        });
      } else {
        this.$router.push({
          name: "three",
          query: {
            succe: this.tableData,
            eId:this.$route.query.eId
          },
        });
      }
    },
    // 导入非收费excel页面获取数据
    async importToResult() {
      let resdata = _qs.stringify({
          evaluationsId: this.$route.query.eId, 
        });
      let { data: res } = await this.$axios.importToResult(resdata);
      // console.log(res)
      if (res.code == 0) {
        this.tableData = res.data;
      } else if (res.data.code == 401) {
        this.$router.push("/login");
      }
    },
    // excel导入过程中
    uploading(event, file, fileList) {
      this.dialogVisibleloding = true;
    },
    // 导入成功后执行此操作
    success(res, file, fileList) {
      // console.log(res,'导入');
      this.tableData = res.data;
      let that = this;
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 0) {
        this.dialogVisibleloding = false;
        this.$message.success("导入成功");
        that.tableData = res.data;
        this.$refs.uploadone.clearFiles();
      } else if ((res.msg = "请按照模板上传正确格式的excel")) {
        this.$alert("请按照模板上传正确格式的excel", {
          confirmButtonText: "确定",
          callback: (action) => {
            this.dialogVisibleloding = false;
            this.$refs.uploadone.clearFiles();
          },
        });
      }
    },
    // 点击下载Excel模版
    async excel() {
      let params = {
        type: 0,
      };
      let res = await this.$axios.downExcel(params);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.status == 200) {
        let blob = new Blob([res.data], { type: "application/x-xls" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        //配置下载的文件名
        link.download = "非收费医疗项目模板" + ".xls";
        link.click();
      }
    },
  },
};
</script>
<style>
.el-icon-edit,
.el-icon-delete-solid {
  cursor: pointer;
  font-size: 20px;
}

.el-icon-download,
.el-icon-circle-plus-outline {
  font-size: 18px;
  font-weight: bold;
}
.el-icon-upload {
  font-size: 20px;
}
.elbutton {
  width: 170px;
  height: 60px;
  font-size: 16px;
  margin-left: 30px !important;
}
.el-main {
  width: 80%;
  background: white;
  margin: 0 auto;
  margin-top: 30px;
}
.nofee .tabhea {
  margin-top: 44px;
  text-align: center;
}

.el-upload-list__item .el-icon-upload-success {
  display: none;
}
.el-upload-list__item-name {
  display: none;
}

.el-table thead {
  color: black;
}
.price {
  margin: 0 auto;
}

.price-table {
  margin: 0 auto;
  margin-top: 40px;
}

.el-form--inline .el-form-item__label {
  font-weight: 600;
}

.bigform {
  text-align: center;
}
</style>
