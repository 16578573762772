<template>
  <el-container style="display: block">
    <!-- <el-header>医疗服务项目标化价值智能测算系统</el-header> -->
    <commheader />
    <el-main>
      <div class="price two">
        <!-- 第一步 -->
        <h3 class="tabhea">第一步：设置医务人员平均薪资（时薪）</h3>
        <h6 class="twotex">时薪计算：（月平均薪资÷21.75天）÷8=时薪</h6>
        <div class="">
          <el-form
            ref="staffRef"
            :model="staff"
            :rules="staffRules"
            :inline="true"
            class="staff"
          >
            <el-form-item label="医生：" prop="doctor">
              <el-input v-model="staff.doctor"></el-input>
            </el-form-item>
            <el-form-item label="护士：" prop="nurse">
              <el-input v-model="staff.nurse"></el-input>
            </el-form-item>
            <el-form-item label="技师：" prop="technician">
              <el-input v-model="staff.technician"></el-input>
            </el-form-item>
            <el-form-item label="勤工：" prop="workhard">
              <el-input v-model="staff.workhard"></el-input>
            </el-form-item>
            <el-form-item class="medical-bc">
              <el-button @click="$router.back(-1)" class="medical-preservation"
                >上一步</el-button
              >
              <el-button
                class="medical-preservation"
                @click="medicalbtn"
                type="primary"
                >下一步</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import Bus from "../../../config/Bus";
import _qs from "qs";
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value && String(value) !== "0") {
        return callback(new Error("请设置医务人员成本！"));
      }
      setTimeout(() => {
        // console.log("number", Number.isInteger(value))
        if (!/^\d+$|^\d*\.\d+$/g.test(value)) {
          callback(new Error("请正确设置医务人员成本！"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      // 医护人员成本
      staff: {
        workhard: "", //工勤
        technician: "", //技师
        nurse: "", //护士
        doctor: "", //医生
      },
      // 表达验证规则
      staffRules: {
        workhard: [{ required: true, validator: checkAge, trigger: "blur" }],
        technician: [{ required: true, validator: checkAge, trigger: "blur" }],
        nurse: [{ required: true, validator: checkAge, trigger: "blur" }],
        doctor: [{ required: true, validator: checkAge, trigger: "blur" }],
      },
    };
  },
  computed: {
    token() {
      return {
        Authorization: "Bearer " + window.sessionStorage.getItem("token"),
      };
    },
  },
  created() {
    Bus.$on("staff", (val) => {
      this.staff = val;
    });
  },
  beforeDestroy() {
    Bus.$off("staff");
  },
  methods: {
    // 点击下一步
    medicalbtn() {
      this.$refs.staffRef.validate(async (valid) => {
        if (!valid) return;
        let that = this;
        let data = _qs.stringify({
          one: that.staff.doctor, //医生
          two: that.staff.nurse, //护士
          three: that.staff.technician, //技师
          four: that.staff.workhard, //工勤
          type: 1,
        });
        let res = await this.$axios.saveSalary(data);
        // console.log(res);
        let staff = JSON.stringify(this.staff);
        if (res.data.code == 0) {
          this.$router.push({
            name: "fone",
            query: {
              staff: staff,
            },
          });
        } else if (res.data.code == 401) {
          this.$router.push("/login");
        }
      });
    },
  },
};
</script>
<style>
.staff .el-form-item__label {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}
.staff .el-input .el-input__inner {
  height: 50px;
}

.el-main {
  width: 80%;
  background: white;
  margin: 0 auto;
  margin-top: 30px;
}
.staff .el-form--inline .el-form-item {
  display: block;
}
.staff {
  padding: 15px 40px;
  margin-top: 15px;
  width: 328px;
  margin: 0 auto;
}
.twotex {
  text-align: center;
}
.price {
  margin: 0 auto;
}
.two .tabhea {
  text-align: center;
}
.medical-bc {
  margin-left: 0px;
  margin-top: 50px;
}
.medical-preservation {
  width: 120px;
  height: 50px;
  line-height: 0px;
  display: inline;
  margin-left: 20px !important;
}
</style>
