<template>
  <el-container style="display:block">
    <!-- <el-header>医疗服务项目标化价值智能测算系统</el-header> -->
    <commheader />
    <el-main>
      <div class="price three">
        <el-form ref="form" class="bigform" :model="form" :inline="true">
          <el-form-item label="调整测算比例：">
            <el-input v-model="form.ratio"></el-input>
          </el-form-item>
          <el-button
            class="threebtn"
            @click="calculationPriceRatio"
            type="primary"
            plain
            >计算标化价值</el-button
          >
          <el-button class="threebtn" @click="exportExce" type="primary" plain
            >下载测算结果</el-button
          >
          <el-button
            type="primary"
            @click="nationa"
            class="threebtn"
            plain
            style="width: 250px"
            >全国标准医疗服务项目检索库</el-button
          >
          <el-button type="primary" @click="backftw" class="threebtn"
            >上一步</el-button
          >
        </el-form>
        <h3 class="tabheaa">第三步：设置系数、计算标化价值</h3>
        <el-table
          id="table"
          :data="tableData"
          ref="tables"
          stripe
          border
          class="price-table"
        >
          <el-table-column prop="id" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column
            prop="code"
            label="项目编码"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column prop="name" label="项目名称" align="center">
          </el-table-column>
          <el-table-column
            prop="lowGoodsUseLevel"
            label="耗材"
            width="55"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="doctorNums"
            label="医生"
            width="55"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="nursingNums"
            label="护士"
            width="55"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="technicianNums"
            label="技师"
            width="60"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="workAttendanceNums"
            label="工勤"
            width="55"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="averageMinute"
            label="耗时(分钟)"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="difficultyRatioClass"
            label="技术难度分类"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="skillDifficulty"
            label="技术难度系数"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="riskRatioClass"
            label="风险程度分类"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="risk"
            label="风险程度系数"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="offset"
            label="成本偏移"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="company"
            label="单位"
            align="center"
            width="105"
          ></el-table-column>
          <el-table-column
            prop="currentPrice"
            label="现行价格"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="priceRatio"
            label="标化价值"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="priceIndex"
            label="现行价格背离指数"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="" label="操作" align="center" width="75">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="nonedit(scope.row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 点击修改的弹出框 -->
      <el-dialog
        title="重新计算"
        :visible.sync="dialogVisible"
        width="480px"
        top="10vh"
      >
        <el-form
          ref="addRef"
          :model="add"
          :inline="true"
          class="add"
          label-width="130px"
          :rules="addRules"
        >
          <el-form-item label="项目编码：">
            <el-input v-model="add.code" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="项目名称：">
            <el-input v-model="add.name" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="耗材：" prop="lowGoodsUseLevel">
            <el-input v-model="add.lowGoodsUseLevel"></el-input>
          </el-form-item>
          <el-form-item label="医生：" prop="doctorNums">
            <el-input v-model="add.doctorNums"></el-input>
          </el-form-item>
          <el-form-item label="护士：" prop="nursingNums">
            <el-input v-model="add.nursingNums"></el-input>
          </el-form-item>
          <el-form-item label="技师：" prop="technicianNums">
            <el-input v-model="add.technicianNums"></el-input>
          </el-form-item>
          <el-form-item label="工勤：" prop="workAttendanceNums">
            <el-input v-model="add.workAttendanceNums"></el-input>
          </el-form-item>
          <el-form-item label="耗时：" prop="averageValue">
            <el-input v-model="add.averageValue"></el-input>
          </el-form-item>
          <el-form-item label="调整测算比例：" prop="offset">
            <el-input v-model="add.offset"></el-input>
          </el-form-item>
          <el-form-item class="addbot">
            <el-button @click="nonqux">取 消</el-button>
            <el-button type="primary" @click="nonclose">重新计算</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import Bus from "../../../config/Bus";
import _qs from "qs";
export default {
  name: "Upload",
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value && String(value) !== "0") {
        return callback(new Error("不能为空"));
      }
      setTimeout(() => {
        // console.log("number", Number.isInteger(value))
        if (!/^\d+$|^\d*\.\d+$/g.test(value)) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      dialogVisible: false,
      form: {
        ratio: "1.0", //比价成本偏移
      },
      id: "",
      // 操作修改的数据
      add: {
        code: "", //项目编码
        name: "", //项目名称
        lowGoodsUseLevel: "", //耗材
        doctorNums: "", //医生
        nursingNums: "", //护士
        technicianNums: "", //技师
        workAttendanceNums: "", //工勤
        averageValue: "", //耗时
        offset: "", //比价成本偏移
      },
      // 表格数据
      tableData: [],
      // 表达验证规则
      addRules: {
        lowGoodsUseLevel: [
          { required: true, validator: checkAge, trigger: "blur" },
        ],
        doctorNums: [{ required: true, validator: checkAge, trigger: "blur" }],
        nursingNums: [{ required: true, validator: checkAge, trigger: "blur" }],
        technicianNums: [
          { required: true, validator: checkAge, trigger: "blur" },
        ],
        workAttendanceNums: [
          { required: true, validator: checkAge, trigger: "blur" },
        ],
        averageValue: [
          { required: true, validator: checkAge, trigger: "blur" },
        ],
        offset: [{ required: true, validator: checkAge, trigger: "blur" }],
      },
    };
  },
  computed: {
    token() {
      return {
        Authorization: "Bearer " + window.sessionStorage.getItem("token"),
      };
    },
  },
  created() {
    // 直接计算比价成本
    this.calculationPriceRatio();
  },
  mounted() {},
  watch: {},
  methods: {
    // 点击上一步
    backftw() {
      this.$router.go(-1);
      let fsucce = this.$route.query.fsucce;
      setTimeout(() => {
        Bus.$emit("fsucce", fsucce);
      }, 100);
    },
    //点击全国标准医疗服务项目检索库
    nationa() {
      let routeUrl = this.$router.resolve({
        path: "/national",
      });

      window.open(routeUrl.href, "_blank");
    },
    // 点击操作修改的时候
    nonedit(e) {
      this.dialogVisible = true;
      this.id = e.id;
      this.add = e;
    },
    // 点击取消
    nonqux() {
      this.dialogVisible = false;
    },
    // 点击重新计算
    nonclose() {
      this.$refs.addRef.validate(async (valid) => {
        if (!valid) return;
        this.dialogVisible = false;
        let data = _qs.stringify({
          type: 1,
          id: this.id, //序号
          consume: this.add.lowGoodsUseLevel, //耗材
          doctor: this.add.doctorNums, //医生
          nursing: this.add.nursingNums, //护士
          technician: this.add.technicianNums, //技师
          work: this.add.workAttendanceNums, //工勤
          average: this.add.averageValue, //耗时
          offset: this.add.offset, //比价成本偏移
        });
        let res = await this.$axios.updateResult(data);
        // console.log(res);
        if (res.data.code == 0) {
          let obj = this.tableData;
          let a = res.data.data;
          let id = this.id;
          Object.keys(obj).forEach(function (key) {
            Object.keys(a).forEach(function (akey) {
              obj[id - 1][akey] = a[akey];
            });
          });
        } else if (res.data.code == 401) {
          this.$router.push("/login");
        }
      });
    },
    // 计算比价成本
    async calculationPriceRatio() {
      let that = this;
      let data = _qs.stringify({
        people: "1",
        ratio: this.form.ratio,
        type: 1,
      });
      let res = await this.$axios.calculationPriceRatio(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 0) {
        that.tableData = res.data.data;
      }
    },
    //获取当前年月日
    addDate() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
      };
      const newmonth = date.month > 10 ? date.month : "0" + date.month;
      const day = date.date > 10 ? date.date : "0" + date.date;
      let updateTime = date.year + "-" + newmonth + "-" + day;
      return updateTime;
    },
    // 点击下载比价成本
    //定义下载Excel表格事件
    //下载数据
    async exportExce(value) {
      let params = {
        type: 1,
      };
      let res = await this.$axios.exportExcel(params);
      // console.log(res);
      if (res.status == 200) {
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.status == 200) {
          let updateTime = this.addDate();
          let blob = new Blob([res.data], { type: "application/x-xls" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          //配置下载的文件名
          link.download = "收费性项目" + updateTime + ".xls";
          link.click();
        }
      }
    },
  },
};
</script>
<style>
.el-icon-edit {
  font-size: 20px;
  cursor: pointer;
}

.price.three .el-input__inner {
  width: 100px;
  height: 50px;
}

.price.three .el-form-item__label {
  font-size: 16px;
  line-height: 50px;
  height: 50px;
}

.threebtn {
  height: 50px;
  width: 160px;
  font-size: 16px;
}

.el-main {
  width: 80%;
  background: white;
  margin: 0 auto;
  margin-top: 30px;
}
.tabheaa {
  text-align: center;
}


.el-upload-list__item-name {
  display: none;
}

.el-table thead {
  color: black;
}
.price {
  margin: 0 auto;
}

.price-table {
  margin: 0 auto;
  margin-top: 40px;
  font-size: 15px; 
  margin: 0 auto;
}

.el-form--inline .el-form-item__label {
  font-weight: 600;
}

.bigform {
  text-align: center;
}

.addbot {
  width: 190px;
  margin-left: 130px;
}
</style>
