// 模块接口文件，暴漏给外部模块使用

// 导入核心模块
import { request } from "./core";
// 导入请求方式
import METHODS from "./methods";
// 导入请求接口
import URL from "./url";

const API = {
    // 登录接口
    login(data) {
        return request(METHODS.POST, URL.login, data);
    },
    //医护人员设置
    saveSalary(data) {
        return request(METHODS.POST, URL.saveSalary, data);
    },
    // 下载excel模板
    downExcel(data) {
        return request(METHODS.GET, URL.downExcel, { params: data, responseType: 'arraybuffer' });
    },
    // 导入数据
    importExcel(data) {
        return request(METHODS.POST, URL.importExcel, data);
    },
    // 计算比价成本
    calculationPriceRatio(data) {
        return request(METHODS.POST, URL.calculationPriceRatio, data);
    },
    // 下载比价成本
    exportExcel(data) {
        return request(METHODS.GET, URL.exportExcel, { params: data, responseType: 'arraybuffer' });
    },
    // 重新计算
    updateResult(data) {
        return request(METHODS.POST, URL.updateResult, data);
    },
    // 全国
    findServiceDetails(data) {
        return request(METHODS.GET, URL.findServiceDetails, { params: data });
    },
    // 添加测评数据
    saveEvaluations(data) {
        return request(METHODS.POST, URL.saveEvaluations, data);
    },
    // 分页显示测评数据列表
    getEvaluation(data) {
        return request(METHODS.GET, URL.getEvaluation, { params: data });
    },
    // 计算测评数据
    calculationResult(data) {
        return request(METHODS.POST, URL.calculationResult, data);
    },
    // 修改单条测评结果数据状态
    updateDetails(data) {
        return request(METHODS.POST, URL.updateDetails, data);
    },
    // 项目跳转接口
    getAppraisalDetails(data) {
        return request(METHODS.GET, URL.getAppraisalDetails, { params: data });
    },
    // 根据状态查询测评结果
    selectCalculation(data) {
        return request(METHODS.POST, URL.selectCalculation, data);
    },
    // 导出测评结果excel
    exportCalculationExcel(data) {
        return request(METHODS.GET, URL.exportCalculationExcel, { params: data, responseType: 'arraybuffer' });
    },
    // 导入测评数据excel
    importEvaluationExcel(data) {
        return request(METHODS.POST, URL.importEvaluationExcel, data);
    },
    // 导入非收费excel页面获取数据
    importToResult(data) {
        return request(METHODS.POST, URL.importToResult,data);
    },
    // 删除测评模型
    removeEvaluations(data) {
        return request(METHODS.POST, URL.removeEvaluations, data);
    },
};

export default {
    // 将对象注入到Vue全局
    install(Vue) {
        Vue.prototype.$axios = API;
    }
};

