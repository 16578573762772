import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/Login';
import Index from '../components/Index/index';
import Nonfee from '../components/Nonfee/nonfee';
import Ntwo from '../components/Nonfee/two';
import Nthree from '../components/Nonfee/three';
import Fee from '../components/Fee/fee';
import Fone from '../components/Fee/fone';
import Fthree from '../components/Fee/fthree';
import National from '../components/National/national';
import Result from '../components/Result/result';
import Rtwo from '../components/Result/rtwo';
import Rcaofev from '../components/Result/rcaofev';
import Echart from '../components/Echart/echart';
Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}


const router = new Router({
  routes: [
    // 登录页面
    {
      path: '/',
      redirect: {
          name: 'login'
      },
      component: Login
   },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/index',
      name: 'index',
      component: Index,
    },
    // 非收费第一步
    {
      path: '/nonfee',
      name: 'nonfee',
      component: Nonfee,
    },
    // 收费第二步
    {
      path: '/fee',
      name: 'fee',
      component: Fee,
    },
    // 非收费的第二步
    {
      path: '/two',
      name: 'two',
      component: Ntwo,
    },
    // 非收费的第三步
    {
      path: '/three',
      name: 'three',
      component: Nthree,
    },
    // 收费的第二步
    {
      path: '/fone',
      name: 'fone',
      component: Fone,
    },
    // 收费的第三步
    {
      path: '/fthree',
      name: 'fthree',
      component: Fthree,
    },
    // 全国
    {
      path: '/national',
      name: 'national',
      component: National,
    },
    // 测评结果调整第一步
    {
      path: '/result',
      name: 'result',
      component: Result,
    },
    // 测评结果调整第二步
    {
      path: '/rtwo',
      name: 'rtwo',
      component: Rtwo,
    },
    // 测评结果调整第三步
    {
      path: '/rcaofev',
      name: 'rcaofev',
      component: Rcaofev,
    },
    // 曲线图
    {
      path: '/echart',
      name: 'echart',
      component: Echart,
    }
  ]
})



// 为路由对象，添加beforeEach导航守卫
router.beforeEach((to, from, next) => {
  // 如果用户访问的登录页，直接放行
  if (to.path == '/login') return next()
  // 从sessionStorage中获取到保存的token值
  const tokenStr = window.sessionStorage.getItem('token')
  // 没有token,强制跳转到登录页
  if (!tokenStr) return next('/login')
  next()
})

// 暴露路由器
export default router
