// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
// 引入element组件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
// 引入全局的css
import '../src/assets/css/global.css'
// 引入icon
import "@/assets/icon/shouye/font_yhfu96341d/iconfont.css";

// 接口配置
import axios from "axios";
import global_ from '../config/env'
Vue.prototype.GLOBAL = global_;
//配置请求的根路径
axios.defaults.baseURL = global_; 
Vue.prototype.$http = axios

// 引入echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts


// 树形组件
import tree from 'vue-table-with-tree-grid'
Vue.component('tree', tree)

// 全局引入顶部
import commheader from "./components/Header";
Vue.component('commheader', commheader)

import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)

Vue.config.productionTip = false

// 导入请求
import API from "../utils/index";
// 挂载到Vue
Vue.use(API);


/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})
