<template>
  <div ref="scorll_top">
    <el-container class="rtwo"  style="display:block">
      <!-- <el-header>医疗服务项目标化价值智能测算系统</el-header> -->
      <commheader />
      <el-main class="rtwoMain">
        <div class="rtwonew">
          <div class="rtwotop">
            <el-form class="rtwoF">
              <el-button type="primary" plain class="rtwobtn" @click="caofev"
                >计算测评结果</el-button
              >
              <el-upload
                ref="rtwoUpload"
                class="upload-demo"
                :headers="token"
                :action="downurl"
                :data="{ id: this.eId }"
                :file-list="fileList"
                :limit="limitUpload"
                style="display: inline-block"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                :on-success="success"
                :on-progress="uploading"
              >
                <el-button type="primary" plain class="rtwobtn"
                  >导入原始测评结果</el-button
                >
              </el-upload>
              <el-button
                type="primary"
                @click="$router.back(-1)"
                class="rtwobtn"
                >上一步</el-button
              >
            </el-form>
          </div>
          <!-- 第一步 -->
          <h3 class="rtwotit">第二步：整理测评数据</h3>
          <div class="textDiv">
            <div>测评项目：{{ pricename }}</div>
            <div class="rtwotexer">项目编码：{{ priceCode }}</div>
          </div>
          <div class="cesuanzhi">
            <!-- <div class="rtworow">
              <div>测评项目：{{ pricename }}</div>
              <div class="rtwotexer">项目编码：{{ priceCode }}</div>
            </div> -->
            <div class="rtworow">
              <el-form class="rform">
                <el-form-item label="测评项目">
                  <el-select
                    v-model="rtwoItemvalue"
                    @change="rtwoItemChange(rtwoItemvalue)"
                  >
                    <el-option
                      v-for="item in rtwoItem"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="状态" class="rfoitem rfzt">
                  <el-select
                    v-model="rtwoFormvalue"
                    @change="rtwoItemStatusChange(rtwoFormvalue)"
                  >
                    <el-option
                      v-for="item in rtwoForm"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <el-table
            id="table"
            :data="resuData"
            style="width: 85%; font-size: 15px; margin: 0 auto"
            border
            class="rtwotable"
            :row-class-name="tableRowClassName"
            ref="table"
          >
            <el-table-column
              type="index"
              label="序号"
              width="55"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="consumables" label="耗材" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.consumables }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="doctor" label="医生" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.doctor }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="nursing" label="护士" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.nursing }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="technician" label="技师" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.technician }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="work" label="工勤" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.work }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="average"
              label="耗时(小时)"
              align="center"
              width="95"
            >
            </el-table-column>
            <el-table-column prop="skill" label="技术难度" align="center">
              <template slot-scope="scope">
                <span v-if="judgeConsumables(scope.row.skill)">{{
                  scope.row.skill
                }}</span>
                <span v-else class="numRen">{{ scope.row.skill }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="risk" label="风险系数" align="center">
              <template slot-scope="scope">
                <span v-if="judgeConsumables(scope.row.risk)">{{
                  scope.row.risk
                }}</span>
                <span v-else class="numRen">{{ scope.row.risk }}</span>
              </template>
            </el-table-column>
            <el-table-column label="状态" align="center">
              <template slot-scope="scope">
                <div class="activeStatus" v-if="scope.row.deleteFlag == 0">
                  √
                </div>
                <div class="noStatus" v-else>√</div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" width="80" align="center">
              <template slot-scope="scope">
                <i
                  class="el-icon-refresh"
                  @click="updateDetails(scope.row)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
          <div
            style="width: 85%; margin: 0 auto"
            :class="isTopDiv ? 'TopDiv' : 'TopDivHidd'"
          >
            <el-table
              :data="topTableData"
              style="width: 66.2%; font-size: 15px; position: fixed; top: 0"
              border
              class="topTable"
            >
              <el-table-column
                type="index"
                label="序号"
                width="55"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="consumables" label="耗材" align="center">
              </el-table-column>
              <el-table-column prop="doctor" label="医生" align="center">
              </el-table-column>
              <el-table-column prop="nursing" label="护士" align="center">
              </el-table-column>
              <el-table-column prop="technician" label="技师" align="center">
              </el-table-column>
              <el-table-column prop="work" label="工勤" align="center">
              </el-table-column>
              <el-table-column
                prop="average"
                label="耗时(小时)"
                align="center"
                width="95"
              >
              </el-table-column>
              <el-table-column prop="skill" label="技术难度" align="center">
              </el-table-column>
              <el-table-column prop="risk" label="风险系数" align="center">
              </el-table-column>
              <el-table-column label="状态" align="center"> </el-table-column>
              <el-table-column prop="" label="操作" width="80" align="center">
              </el-table-column>
            </el-table>
          </div>
          <div class="rtwodiu">
            <el-form class="rtwobot">
              <el-button
                type="primary"
                plain
                class="rtwobtn"
                @click="oneAppDetai"
                >第一个项目</el-button
              >
              <el-button
                type="primary"
                plain
                class="rtwobtn"
                @click="preAppDetai"
                >上一个项目</el-button
              >
              <el-button
                type="primary"
                plain
                class="rtwobtn"
                @click="nextAppDetai"
                >下一个项目</el-button
              >
              <el-button
                type="primary"
                plain
                class="rtwobtn"
                @click="lastAppDetai"
                >最后一个项目</el-button
              >
            </el-form>
          </div>
        </div>
      </el-main>
      <el-dialog
        title="正在上传中，请稍等~"
        :visible.sync="dialogVisibleloding"
        width="30%"
        :show-close="false"
        center
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
      </el-dialog>
    </el-container>
  </div>
</template>
<script>
import _qs from "qs";
export default {
  data() {
    return {
      topTableData: [],
      // 导入数据的接口
      downurl: this.GLOBAL + "/openApi/priceRatio/importEvaluationExcel",
      fileList: [], // 上传文件的列表
      limitUpload: 1, //最大允许上传个数
      // 表格
      resuData: [],
      //   测评项目
      rtwoItem: [],
      // 测评状态
      rtwoForm: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "移除",
        },
        {
          value: "0",
          label: "正常",
        },
      ],
      rtwoFormvalue: "", //状态
      rtwoItemvalue: "", //current 测评项目选项的值
      onertwoItemvalue: "", //第一个项目
      lastrtwoItemvalue: "", //最后一个项目
      eId: 0, //测评模型id
      id: 0, //测评结果id
      priceCode: 0, //项目编码
      pricename: "xxxx", //测评项目名称
      deleteFlag: "", //状态
      title: [], //所有测评项目
      dialogVisibleloding: false, //正在上传中
      titlength: "", //一共有多少个
      isTopDiv: false,
    };
  },
  computed: {
    token() {
      return {
        Authorization: "Bearer " + window.sessionStorage.getItem("token"),
      };
    },
  },
  created() {
    this.eIdGet();
    // 直接调取接口显示数据
    this.getOneAppraisalDetails();
  },
  // 自动触发
  mounted: function () {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  // 页面销毁前
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {},
  methods: {
    // 获取滚动条的滚动距离
    handleScroll() {
      // console.log(this.$refs.scorll_top);
      if (this.$refs.scorll_top) {
        if (this.$refs.scorll_top.getBoundingClientRect().top < -330) {
          this.isTopDiv = true;
        } else {
          this.isTopDiv = false;
        }
      }
    },
    // 判断表格的颜色
    tableRowClassName({ row }) {
      // 判断耗材
      if (0 <= row.consumables && row.consumables <= 100) {
        // 判断医生
        if (0 <= row.doctor && row.doctor <= 5) {
          // 判断护士
          if (0 <= row.nursing && row.nursing <= 5) {
            // 判断技师
            if (0 <= row.technician && row.technician <= 5) {
              // 判断工勤
              if (0 <= row.work && row.work <= 5) {
                // 判断五个都为零
                if (
                  row.consumables == 0 &&
                  row.doctor == 0 &&
                  row.nursing == 0 &&
                  row.technician == 0 &&
                  row.work == 0
                ) {
                  return "warning-row";
                } else {
                  // 判断技术难度
                  if (1 <= row.skill && row.skill <= 100) {
                    // 判断风险系数
                    if (1 <= row.risk && row.risk <= 100) {
                      // return "";
                      // 判断状态为灰色的时候改变这一行的颜色
                      if (row.deleteFlag != 0) {
                        return "warning-hui";
                      }
                    } else {
                      return "warning-row";
                    }
                  } else {
                    return "warning-row";
                  }
                }
              } else {
                return "warning-row";
              }
            } else {
              return "warning-row";
            }
          } else {
            return "warning-row";
          }
        } else {
          return "warning-row";
        }
      } else {
        return "warning-row";
      }
    },
    // 判断耗材数据
    judgeConsumables(row) {
      if (1 <= row && row <= 100) {
        return true;
      } else {
        return false;
      }
    },
    // 判断人力数据
    judgehuman(row) {
      if (1 <= row && row <= 5) {
        return true;
      } else {
        return false;
      }
    },
    // 第一个项目
    oneAppDetai() {
      this.rtwoItemChange(this.title[0]);
      this.$message.error("当前已是第一个项目");
    },
    // 最后一个项目
    lastAppDetai() {
      this.rtwoItemChange(this.title[this.title.length - 1]);
      this.$message.error("当前已是最后一个项目");
    },
    // 上一个项目
    preAppDetai() {
      if (this.title.indexOf(this.rtwoItemvalue) - 1 < 0) {
        this.$message.error("当前已是第一个项目");
        return false;
      } else {
        if (
          this.title[this.title.indexOf(this.rtwoItemvalue) - 1] ==
          this.onertwoItemvalue
        ) {
          this.rtwoItemChange(
            this.title[this.title.indexOf(this.rtwoItemvalue) - 1]
          );
          this.$message.error("当前已是第一个项目");
          return false;
        } else {
          this.rtwoItemChange(
            this.title[this.title.indexOf(this.rtwoItemvalue) - 1]
          );
        }
      }
    },
    // 下一个项目
    nextAppDetai() {
      if (this.title.indexOf(this.rtwoItemvalue) + 1 > this.titlength - 1) {
        this.$message.error("当前已是最后一个项目");
        return false;
      } else {
        if (
          this.title[this.title.indexOf(this.rtwoItemvalue) + 1] ==
          this.lastrtwoItemvalue
        ) {
          this.rtwoItemChange(
            this.title[this.title.indexOf(this.rtwoItemvalue) + 1]
          );
          this.$message.error("当前已是最后一个项目");
          return false;
        } else {
          this.rtwoItemChange(
            this.title[this.title.indexOf(this.rtwoItemvalue) + 1]
          );
        }
      }
    },
    // 获取id值
    eIdGet() {
      this.eId = this.$route.query.eId;
    },
    // 点击计算测评结果
    caofev() {
      this.$router.push({
        path: "/rcaofev",
        query: {
          eId: this.$route.query.eId,
        },
      });
    },
    // 状态选项卡
    rtwoItemStatusChange(rtwoFormvalue) {
      this.rtwoFormvalue = rtwoFormvalue;
      this.getAppraisalDetails();
    },
    // 测评项目选项卡
    rtwoItemChange(rtwoItemvalue) {
      this.rtwoItemvalue = rtwoItemvalue;
      this.getAppraisalDetails();
    },
    // 刚开始进来的时候调取的接口
    async getOneAppraisalDetails() {
      let data = {
        eId: this.eId,
      };
      let { data: res } = await this.$axios.getAppraisalDetails(data);
      // console.log(res);
      if (res.code == 0) {
        // 项目编码
        this.priceCode = res.priceCode;
        // 表格数据
        this.resuData = res.list;
        // 测评项目名称
        this.pricename = res.name;
        // 所有测评项目
        this.title = res.title;
        // 一共有几个title
        this.titlength = res.title.length;
        //测评项目的选项卡
        this.rtwoItem = res.titleDetails;
        // 测评项目选项的值
        this.rtwoItemvalue = res.current;
        // 第一个项目
        this.onertwoItemvalue = res.title[0];
        //最后一个项目
        this.lastrtwoItemvalue = res.title[res.title.length - 1];
      } else if (res.code == 401) {
        this.$router.push("/login");
      }
    },
    // 项目跳转接口
    async getAppraisalDetails() {
      let data = {
        appId: this.rtwoItemvalue,
        eId: this.eId,
        deleteFlag: this.rtwoFormvalue,
      };
      // console.log(this.rtwoFormvalue)
      let { data: res } = await this.$axios.getAppraisalDetails(data);
      if (res.code == 0) {
        // 项目编码
        this.priceCode = res.priceCode;
        // 表格数据
        this.resuData = res.list;
        // 测评项目名称
        this.pricename = res.name;
        // 测评项目选项的值
        this.rtwoItemvalue = res.current;
      } else if (res.code == 401) {
        this.$router.push("/login");
      }
    },
    // 修改单条测评结果数据状态
    async updateDetails(row) {
      // console.log(row.deleteFlag,'当前')
      if (row.deleteFlag == false) {
        this.deleteFlag = 1;
      } else if (row.deleteFlag == true) {
        this.deleteFlag = 0;
      } else if (row.deleteFlag == 1) {
        this.deleteFlag = 0;
      } else if (row.deleteFlag == 0) {
        this.deleteFlag = 1;
      }

      let data = _qs.stringify({
        id: row.id,
        status: this.deleteFlag,
      });
      let { data: res } = await this.$axios.updateDetails(data);
      // console.log(res);
      if (res.code === 0) {
        if (row.deleteFlag == false) {
          row.deleteFlag = true;
        } else if (row.deleteFlag == 0) {
          row.deleteFlag = true;
        } else if (row.deleteFlag == 1) {
          row.deleteFlag = false;
        } else if (row.deleteFlag == true) {
          row.deleteFlag = false;
        }
      } else if (res.code == 401) {
        this.$router.push("/login");
      }
    },
    // excel导入过程中
    uploading() {
      this.dialogVisibleloding = true;
    },
    // 导入成功后执行此操作
    success(res) {
      // console.log(res, "导入");
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 0) {
        this.dialogVisibleloding = false;
        this.$message.success("导入成功");
        // 项目编码
        this.priceCode = res.priceCode;
        // 表格数据
        this.resuData = res.list;
        // 测评项目名称
        this.pricename = res.name;
        // 测评项目选项的值
        this.rtwoItemvalue = res.current;
        //测评项目的选项卡
        this.rtwoItem = res.titleDetails;
        // 所有测评项目
        this.title = res.title;
        // 一共有几个title
        this.titlength = res.title.length;
        // 第一个项目
        this.onertwoItemvalue = res.title[0];
        //最后一个项目
        this.lastrtwoItemvalue = res.title[res.title.length - 1];
        this.getAppraisalDetails();
        this.$refs.rtwoUpload.clearFiles();
      } else if ((res.msg = "请上传正确格式的excel")) {
        this.$alert("请上传正确格式的excel", {
          confirmButtonText: "确定",
          callback: () => {
            this.dialogVisibleloding = false;
            this.$refs.rtwoUpload.clearFiles();
          },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.TopDiv {
  .topTable {
    /deep/ .el-table__body-wrapper {
      display: none;
    }
  }
  /deep/ th > .cell {
    color: black;
  }
}

.TopDivHidd {
  display: none;
  .topTable {
    /deep/ .el-table__body-wrapper {
      display: none;
    }
  }
  /deep/ th > .cell {
    color: black;
  }
}

.topTable {
  display: block;
}

.rtwonew {
  margin: 0 auto;
}

.rtwotit {
  text-align: center;
  margin-top: 44px;
  margin-bottom: 30px;
}

.rtwobtn {
  width: 170px;
  height: 60px;
  font-size: 16px;
  margin-left: 30px !important;
}

.rtwoMain {
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
}

.rtwoMain {
  /deep/ .warning-row {
    background: #faecec !important;
    color: red;
  }
  /deep/ .warning-hui {
    background: #eee !important;
  }
}

.rtwotable {
  /deep/ .el-table__header-wrapper .el-table__header .has-gutter {
    color: black !important;
  }
  /deep/ .el-icon-refresh {
    width: 50px;
    font-size: 20px;
    color: blue;
    font-weight: bold;
  }
}

.rtworow {
  // display: flex;
  // flex-direction: row;
  margin-top: 20px;
  float: right;
  > div {
    font-size: 18px;
    font-weight: bold;
  }
  > .rtwotexer {
    margin-left: 20px;
  }
}

.rtwotwo {
  width: 85%;
  margin: 0 auto;
  > .el-col.el-col-11 {
    height: 60px;
  }
}

.rform {
  display: flex;
  flex-direction: row;
  > .el-form-item {
    display: flex;
    margin-bottom: 10px;
  }
  > .rfzt {
    margin-left: 15px;
  }
  /deep/ .el-form-item__label {
    font-size: 16px !important;
    line-height: 50px;
  }
  /deep/ .el-input--suffix .el-input__inner {
    height: 50px;
  }
}

.cesuanzhi {
  // display: flex;
  // justify-content: space-between;
  width: 85%;
  margin: 0 auto;
  // align-items: center;
}

.rtwodiu {
  width: 85%;
  margin: 0 auto;
  .rtwobot {
    float: right;
    margin-top: 20px;
  }
}

.rtwotop {
  width: 85%;
  margin: 0 auto;
  > .rtwoF {
    display: flex;
    flex-direction: row-reverse;
    /deep/ .el-upload-list.el-upload-list--text {
      display: none !important;
    }
  }
}

.el-icon-refresh:hover {
  cursor: pointer;
}

.activeStatus {
  background: rgb(102, 204, 0);
}

.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.noStatus {
  background: #ccc;
}

.numRen {
  color: red;
}

.textDiv {
  width: 85%;
  margin: 0 auto;
  display: flex;
  font-weight: bold;
  div {
    margin-right: 20px;
  }
}
</style>
