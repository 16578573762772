'use strict';
/*
 * 配置编译环境和线上环境之间的切换
 * BASE_URL: 域名地址
 */
// console.log('NOW_ENV', process.env.NODE_ENV)
let BASE_URL = ''

if (process.env.NODE_ENV === 'development') {
  BASE_URL = 'http://172.16.10.101:8089' //  wb
  // BASE_URL = 'http://172.16.10.108:8089' //  npm run dev
  // BASE_URL = 'http://172.16.10.202:8089' // 测试 npm run dev
} else if (process.env.NODE_ENV === 'testing') {
  BASE_URL = 'http://172.16.10.202:8089' //  npm run test
} else if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'https://price.yltcloud.com' // 线上 npm run prod
}

// console.log(BASE_URL)
// npm run dev 测试运行
// npm run build 线上打包
// npm run test 打包测试环境
// npm run prod 线上运行

//导出
export default BASE_URL
