<template>
  <el-container style="display:block">
    <!-- <el-header>医疗服务项目标化价值智能测算系统</el-header> -->
    <commheader />
    <el-main>
      <div class="nofee price">
        <el-form ref="form" class="bigform" :model="form" :inline="true">
          <el-button
            @click="excel"
            icon="el-icon-download"
            class="elbutton"
            type="primary"
            plain
            >下载Excel模版</el-button
          >
          <el-upload
            ref="uploadone"
            class="upload-demo"
            :headers="token"
            :action="downurl"
            :data="{ type: 1 }"
            :file-list="fileList"
            :limit="limitUpload"
            style="display: inline-block"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            :on-success="success"
          >
            <el-button type="primary" class="elbutton"
              >导入医院数据<i class="el-icon-upload el-icon--right"></i
            ></el-button>
          </el-upload>
          <el-button @click="backnonfee" class="elbutton" type="primary"
            >上一步</el-button
          >
          <el-button @click="oneBut" class="elbutton" type="primary"
            >下一步</el-button
          >
        </el-form>
        <h3 class="tabhea">第二步：导入添加基础数据</h3>
        <el-table
          id="table"
          stripe
          :data="tableData"
          ref="tables"
          style="width: 80%; font-size: 15px"
          border
          class="price-table"
        >
          <el-table-column prop="data0" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="data1" label="科室名称" align="center">
          </el-table-column>
          <el-table-column prop="data2" label="名称" align="center">
          </el-table-column>
          <el-table-column
            prop="data3"
            label="收费编码"
            align="center"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="data4"
            label="地方项目编码"
            align="center"
            width="115"
          >
          </el-table-column>
          <el-table-column
            prop="data5"
            label="国标项目编码"
            align="center"
            width="115"
          >
          </el-table-column>
          <el-table-column prop="data6" label="项目分类" align="center">
          </el-table-column>
          <el-table-column prop="data7" label="单位" align="center" width="105">
          </el-table-column>
          <el-table-column
            prop="data8"
            label="收费价格(单价)"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="data9"
            label="月发生量"
            align="center"
            width="85"
          >
          </el-table-column>
        </el-table>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import Bus from "../../../config/Bus";
import _qs from "qs";
export default {
  name: "Upload",
  data() {
    return {
      downurl: this.GLOBAL + "/openApi/priceRatio/importExcel",
      form: {
        people: "1.0", //人员系数调整
        ratio: "1.0", //比价成本偏移
      },
      tableHead: [],
      fileList: [], // 上传文件的列表
      limitUpload: 1, //最大允许上传个数
      // 表格数据
      tableData: [],
    };
  },
  computed: {
    token() {
      return {
        Authorization: "Bearer " + window.sessionStorage.getItem("token"),
      };
    },
  },
  created() {
    Bus.$on("fsucce", (val) => {
      this.tableData = val;
    });
  },
  beforeDestroy() {
    Bus.$off("fsucce");
  },
  mounted() {},
  methods: {
    // 返回上一页
    backnonfee() {
      this.$router.go(-1);
      let staff = JSON.parse(this.$route.query.staff);
      setTimeout(() => {
        Bus.$emit("staff", staff);
      }, 100);
    },
    // 点击下一步
    oneBut() {
      // console.log(this.tableData);
      if (this.tableData.length <= 0) {
        this.$alert("请导入基础数据！", {
          confirmButtonText: "确定",
        });
      } else {
        this.$router.push({
          name: "fthree",
          query: {
            fsucce: this.tableData,
          },
        });
      }
    },
    // 导入成功后执行此操作
    success(res, file, fileList) {
      // console.log(res);
      let that = this;
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == "0") {
        this.$message.success("导入成功");
        that.tableData = res.data;
        this.$refs.uploadone.clearFiles();
      } else if ((res.msg = "请按照模板上传正确格式的excel")) {
        this.$alert("请按照模板上传正确格式的excel", {
          confirmButtonText: "确定",
          callback: (action) => {
            this.$refs.uploadone.clearFiles();
          },
        });
      }
    },
    // 点击下载Excel模版
    async excel() {
      let params = {
        type: 1,
      };
      let res = await this.$axios.downExcel(params);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.status == 200) {
        let blob = new Blob([res.data], { type: "application/x-" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        //配置下载的文件名
        link.download = "收费医疗项目模板" + ".xls";
        link.click();
      }
    },
  },
};
</script>
<style>
.el-icon-edit,
.el-icon-delete-solid {
  cursor: pointer;
  font-size: 20px;
}

.el-icon-download,
.el-icon-circle-plus-outline {
  font-size: 18px;
  font-weight: bold;
}
.el-icon-upload {
  font-size: 20px;
}
.elbutton {
  width: 160px;
  height: 50px;
  font-size: 16px;
  margin-left: 30px !important;
}
.el-main {
  width: 80%;
  background: white;
  margin: 0 auto;
  margin-top: 30px;
}
.nofee .tabhea {
  margin-top: 44px;
  text-align: center;
}

.el-upload-list__item .el-icon-upload-success {
  display: none;
}
.el-upload-list__item-name {
  display: none;
}

.el-table thead {
  color: black;
}
.price {
  margin: 0 auto;
}

.price-table {
  margin: 0 auto;
  margin-top: 40px;
}

.el-form--inline .el-form-item__label {
  font-weight: 600;
}

.bigform {
  text-align: center;
}
</style>
