<template>
  <el-container>
    <!-- 背景图 -->
    <el-image
      :src="require('@/assets/img/login/bg2.jpg')"
      class="login-banimg"
    ></el-image>
    <!-- 顶部 -->
    <commheader />
    <!-- 中间内容 -->
    <el-main>
      <div class="BigDiv">
        <div
          class="KuaiDiv"
          style="background: linear-gradient(to right top, #5898f4, #9fe4ed)"
          @click="businessman"
        >
          <div class="iconfont icon-jisuanqilishuai-xianxing-xi"></div>
          <span>非收费项目标化价值计算</span>
        </div>
        <div
          class="KuaiDiv"
          style="background: linear-gradient(to right top, #61d36e, #97ff7d)"
          @click="businessdata"
        >
          <div class="iconfont icon-jisuanqilishuai-xianxing"></div>
          <span>收费医疗项目标化价值计算</span>
        </div>
      </div>
      <div class="BigDiv">
        <div
          class="KuaiDiv"
          style="background: linear-gradient(90deg, #1eaec5, #50e5fa)"
          @click="informationman"
        >
          <div class="iconfont icon-tongjijieguo"></div>
          <span>测评结果调整</span>
        </div>
        <div
          class="KuaiDiv"
          style="background: linear-gradient(90deg, #c735c3, #fa64c5)"
          @click="performanceind"
        >
          <div class="iconfont icon-fuwuxiangmu"></div>
          <span style="text-align:center">全国标准医疗服务项目<br>检索库</span>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      // 医院用户还是科室用户
      isDoctore: "",
    };
  },
  created() {
    this.winty();
  },
  methods: {
    // 点击非收费项目标化价值计算
    businessman() {
      this.$router.push({
        path: "/nonfee",
        query: {
          toIndex: "toIndex",
        },
      });
    },
    // 点击收费医疗项目标化价值计算
    businessdata() {
      this.$router.push({ path: "/fee" });
    },
    //  点击测评结果调整
    informationman() {
      this.$router.push({ path: "/result" });
    },
    // 点击全国标准医疗服务项目检索库
    performanceind() {
      let routeUrl = this.$router.resolve({
        path: "/national",
      });
      window.open(routeUrl.href, "_blank");
    },
    
    winty() {
      if (window.sessionStorage.getItem("type") == 0) {
        this.administrators = "医院";
        this.isDoctore = true;
      } else {
        this.administrators = "科室";
        this.isDoctore = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  display: block;
}
/deep/ .el-header {
  position: relative;
  width: 100%;
  height: 80px !important;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.6)!important;
  // background: rgb(2, 24, 36);
  color: white;
  justify-content: space-between;
}

.login-banimg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.el-main {
  position: relative;
  // top: 50px;
  background: transparent;
  .BigDiv {
    display: flex;
    width: 70%;
    margin: 0 auto;
    margin-top: 10px;
    .KuaiDiv {
      width: 400px;
      height: 250px;
      margin-right: 20px;
      margin-bottom: 20px;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .iconfont {
        font-size: 50px;
        color: white;
        margin-bottom: 20px;
      }
      span {
        color: white;
        font-size: 23px;
        margin-top: 10px;
      }
    }
  }
}
</style>
