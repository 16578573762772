<template>
  <el-container style="display:block">
    <!-- <el-header>医疗服务项目标化价值智能测算系统</el-header> -->
    <commheader />
    <el-main>
      <div class="price national">
        <el-form ref="form" class="bigform" :model="form" :inline="true">
          <el-form-item label="收费编码：">
            <el-input v-model="form.chargecode"></el-input>
          </el-form-item>
          <el-form-item label="项目名称：">
            <el-input v-model="form.entryname"></el-input>
          </el-form-item>
          <el-button
            type="primary"
            class="search"
            icon="el-icon-search"
            @click="findServiceDetails"
            >搜索</el-button
          >
        </el-form>
        <h3 class="tabheaa">全国收费医疗服务项目表</h3>
        <el-table
          id="table"
          :data="tableData"
          ref="tables"
          stripe
          border
          class="price-table"
        >
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="priceCode" label="收费编码" align="center" width="115">
          </el-table-column>
          <el-table-column prop="column1" label="项目编码" align="center">
          </el-table-column>
          <el-table-column prop="name" label="项目名称" align="center">
          </el-table-column>
          <el-table-column
            prop="column2"
            label="低值耗材"
            align="center"
            width="85"
          >
          </el-table-column>
          <el-table-column
            prop="column3"
            label="医生"
            align="center"
            width="55"
          >
          </el-table-column>
          <el-table-column
            prop="column4"
            label="护士"
            align="center"
            width="55"
          >
          </el-table-column>
          <el-table-column
            prop="column5"
            label="技师"
            align="center"
            width="55"
          >
          </el-table-column>
          <el-table-column
            prop="column6"
            label="工勤"
            align="center"
            width="55"
          >
          </el-table-column>
          <el-table-column
            prop="column7"
            label=" 平均耗时(分钟)"
            align="center"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="column8"
            label="技术难度分类"
            align="center"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="column9"
            label="技术难度系数"
            align="center"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="column10"
            label="风险难度分类"
            align="center"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="column11"
            label="风险难度系数"
            align="center"
            width="90"
          ></el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import _qs from "qs";
export default {
  name: "Upload",
  data() {
    return {
      pageSize: 50, //每页显示条数
      pageNum: 0, //当前页数
      total: 0, //总条数
      form: {
        chargecode: "", //收费编码
        entryname: "", //项目名称
      },
      // 表格数据
      tableData: [],
    };
  },
  computed: {
    token() {
      return {
        Authorization: "Bearer " + window.sessionStorage.getItem("token"),
      };
    },
  },
  created() {
    this.findServiceDetails();
  },
  mounted() {},
  watch: {},
  methods: {
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.findServiceDetails();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.findServiceDetails();
    },
    async findServiceDetails() {
      let data = {
        pageNum: this.pageNum, //页数
        pageSize: this.pageSize, //每页显示条数
        priceCode: this.form.chargecode, //编码
        name: this.form.entryname, //名称
      };
      let res = await this.$axios.findServiceDetails(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 200) {
        this.tableData = res.data.rows;
        this.total = res.data.total;
      }
    },
  },
};
</script>
<style>
.el-icon-edit {
  font-size: 20px;
  cursor: pointer;
}

.el-main {
  width: 80%;
  background: white;
  margin: 0 auto;
  margin-top: 30px;
}
.tabheaa {
  text-align: center;
}

.el-upload-list__item-name {
  display: none;
}

.el-table thead {
  color: black;
}
.price {
  margin: 0 auto;
}

.price-table {
  margin: 0 auto;
  margin-top: 40px;
  font-size: 15px;
}

.el-form--inline .el-form-item__label {
  font-weight: 600;
}

.bigform {
  text-align: center;
}
.block {
  text-align: center;
  margin-top: 30px;
}
.price.national .bigform .el-input__inner {
  width: 200px;
  height: 50px;
}
.price.national .bigform .el-form-item__label {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}
.search {
  width: 100px;
  height: 50px;
}
</style>
