<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    // 禁止放大缩小
    // window.addEventListener(
    //   "mousewheel",
    //   function (event) {
    //     if (event.ctrlKey === true || event.metaKey) {
    //       event.preventDefault();
    //     }
    //   },
    //   { passive: false }
    // );
    // //firefox
    // window.addEventListener(
    //   "DOMMouseScroll",
    //   function (event) {
    //     if (event.ctrlKey === true || event.metaKey) {
    //       event.preventDefault();
    //     }
    //   },
    //   { passive: false }
    // );
  },
};
</script>

<style lang="less" scoped>
</style>
