export default {
  login: "/openApi/login", //登录
  saveSalary: "/openApi/priceRatio/saveSalary", //医护人员设置
  downExcel: "/openApi/priceRatio/downExcel", //下载excel模板
  importExcel: "/openApi/priceRatio/importExcel", //导入数据
  calculationPriceRatio: "/openApi/priceRatio/calculationPriceRatio", //计算比价成本
  exportExcel: "/openApi/priceRatio/exportExcel", //下载比价成本
  updateResult: "/openApi/priceRatio/updateResult", //重新计算
  findServiceDetails: "/openApi/priceRatio/findServiceDetails", // 全国
  saveEvaluations: "/openApi/priceRatio/saveEvaluations", //添加测评数据
  getEvaluation: "/openApi/priceRatio/getEvaluation", //分页显示测评数据列表
  calculationResult: "/openApi/priceRatio/calculationResult", //计算测评数据
  updateDetails: "/openApi/priceRatio/updateDetails", //修改单条测评结果数据状态
  getAppraisalDetails: "/openApi/priceRatio/getAppraisalDetails", //项目跳转接口
  selectCalculation: "/openApi/priceRatio/selectCalculation", //根据状态查询测评结果
  exportCalculationExcel: "/openApi/priceRatio/exportCalculationExcel", //导出测评结果excel
  importEvaluationExcel: "/openApi/priceRatio/importEvaluationExcel", // 导入测评数据excel
  importToResult: "/openApi/priceRatio/importToResult", //导入非收费excel页面获取数据
  removeEvaluations: "/openApi/priceRatio/removeEvaluations", //删除测评模型
};

