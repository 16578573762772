<template>
  <el-container style="display:block">
    <!-- <el-header>医疗服务项目标化价值智能测算系统</el-header> -->
    <commheader />
    <el-main>
      <div id="chartColumn" style="width: 100%; height: 650px"></div>
    </el-main>
  </el-container>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "",
  data() {
    return {
      chartColumn: null,
      // echarts底部文字
      bottomText: [],
      // 左侧值
      leftValue: [],
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getEchartValue();
    this.$nextTick(() => {
      this.drawLine();
    });
  },
  watch: {},
  methods: {
    getEchartValue() {
      let self = this;
      // 获取传过来的数据
      let echart = JSON.parse(this.$route.query.echart);
      // console.log(echart);
      echart.forEach(function (obj) {
        // console.log(obj);
        self.bottomText.push(obj.name);
        // console.log(self.bottomText);
        self.leftValue.push(obj.priceRatio);
        // console.log(self.leftValue);
        // name项目名称
        // priceRatio标化价值
      });
    },
    // 绘制echarts图
    drawLine() {
      this.chartColumn = echarts.init(document.getElementById("chartColumn"));
      this.chartColumn.setOption({
        title: { text: "标化价值折线图" },
        tooltip: {},
        grid: {
          bottom: "40%", //y轴文字距离底部的距离
        },
        xAxis: {
          type: "category",
          data: this.bottomText,
          axisLabel: {
            interval: 0, //强制文字产生间隔
            formatter: function (value) {
              //x轴的文字改为竖版显示
              var str = value.split("");
              return str.join("\n");
            },
            textStyle: {
              //文字样式
              color: "black",
              fontSize: 16,
            },
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.leftValue,
            type: "line",
            label: {
              normal: {
                show : true, //让图形上的数字显示
              },
            },
          },
        ],
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  width: 80%;
  background: white;
  margin: 0 auto;
  margin-top: 30px;
}
</style>
