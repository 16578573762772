<template>
  <div class="login-container">
    <el-image :src="require('@/assets/bg.jpg')" class="login-banimg"></el-image>
    <div class="pricetitle">医疗服务项目标化价值智能测算系统</div>
    <el-form
      :model="ruleForm2"
      :rules="rules2"
      status-icon
      ref="ruleForm2"
      label-position="left"
      label-width="0px"
      class="demo-ruleForm login-page"
    >
      <el-form-item prop="username">
        <el-input
          type="text"
          v-model="ruleForm2.username"
          auto-complete="off"
          placeholder="请输入用户名/手机号"
          clearable
          class="logininp"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          v-model="ruleForm2.password"
          auto-complete="off"
          placeholder="密码"
          @keyup.enter.native="handleSubmit"
          clearable
          class="logininp"
        ></el-input>
      </el-form-item>
      <!-- <el-checkbox v-model="checked" class="rememberme">记住密码</el-checkbox> -->
      <el-form-item style="width: 100%">
        <el-button type="primary" style="width: 100%" @click="handleSubmit"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
    <div class="login-bottom">
      2018 All Rights Reserved. 鼎立华康（北京）健康管理有限公司 备案号:
      京ICP备18001073号-3 京公网安备 11010802027953号
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm2: {
        username: "",
        password: "",
      },
      rules2: {
        username: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      checked: false,
    };
  },
  methods: {
    handleSubmit(event) {
      let data = {
        username: this.ruleForm2.username,
        password: this.ruleForm2.password,
      };
      this.$refs.ruleForm2.validate(async (valid) => {
        if (valid) {
          let res = await this.$axios.login(data);
          // console.log(res);
          window.sessionStorage.setItem("token", res.data.token);
          if (res.data.code == 200) {
            this.$router.push({ path: "/index" });
            this.$message({
              message: "登录成功！",
              type: "success",
            });
          } else if (res.data.code == 500) {
            this.$message.error("账号或密码错误！");
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.logininp /deep/ .el-input__inner {
  height: 50px;
}
.pricetitle {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 40px;
}
.login-banimg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.login-container {
  width: 100%;
  height: 100%;
}
.login-page {
  position: relative;
  /* -webkit-border-radius: 5px; */
  /* border-radius: 5px; */
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px;
  background: #fff;
  border: 1px solid #d6d3d3;
  /* box-shadow: 0 0 25px #cac6c6; */
}
label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}

.login-bottom {
  width: 100%;
  position: fixed;
  bottom: 10px;
  font-size: 13px;
  text-align: center;
  color: #666;
}
</style>