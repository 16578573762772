<template>
  <el-container class="rcao"  style="display:block">
    <!-- <el-header>医疗服务项目标化价值智能测算系统</el-header> -->
    <commheader />
    <el-main class="rcaoMain">
      <div class="rcaonew">
        <div class="rcaotop">
          <el-form class="rcaoF">
            <el-button type="primary" plain class="rcaobtn" @click="nonfee"
              >标化价值计算</el-button
            >
            <el-button
              type="primary"
              plain
              class="rcaobtn"
              @click="exportCalculationExcel"
              >导出测评结果数据</el-button
            >
            <el-button type="primary" @click="backRtwo" class="rcaobtn"
              >上一步</el-button
            >
          </el-form>
        </div>
        <!-- 第一步 -->
        <h3 class="rcaotit">第三步：测评结果</h3>
        <div class="cesuanzhi">
          <div class="rcaorow">
            <div>测评值：{{ rcaovalue }}</div>
          </div>
          <div class="rcaorow">
            <el-form class="rform">
              <el-form-item label="项目类别" class="rfoitem rfzt">
                <el-select
                clearable
                  v-model="rcaoFormvalue"
                  @change="selectCalculation(rcaoFormvalue)"
                >
                  <el-option
                    v-for="item in rcaoForm"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table
          id="table"
          stripe
          :data="rcaoData"
          style="width: 85%; font-size: 15px; margin: 0 auto"
          border
          class="rcaotable"
        >
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>

          <el-table-column
            prop="code"
            label="项目编码"
            align="center"
            width="100"
          >
          </el-table-column>

          <el-table-column prop="name" label="项目名称" align="center">
          </el-table-column>
          
          <el-table-column prop="consumables" label="耗材" align="center" width="80">
          </el-table-column>
          <el-table-column prop="doctor" label="医生" align="center" width="80">
          </el-table-column>
          <el-table-column prop="nursing" label="护士" align="center" width="80">
          </el-table-column>
          <el-table-column prop="technician" label="技师" align="center" width="80">
          </el-table-column>
          <el-table-column prop="work" label="工勤" align="center" width="80">
          </el-table-column>
          <el-table-column
            prop="average"
            label="耗时(小时)"
            align="center"
            width="95"
          >
          </el-table-column>
          <el-table-column prop="skill" label="技术难度系数" align="center" width="120">
          </el-table-column>
          <el-table-column prop="risk" label="风险程度系数" align="center" width="120">
          </el-table-column>
        </el-table>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  data() {
    return {
      // 表格
      rcaoData: [],
      // 项目类别
      rcaoForm: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "非收费医疗服务项目",
        },
        {
          value: "2",
          label: "收费医疗服务项目",
        },
      ],
      rcaoFormvalue: "全部",
      rcaovalue: 0, //测评值
      type: 1, //0 全部 1 非收费 2 收费
    };
  },
  computed: {},
  created() {
    this.calculationResult();
    this.selectCalculation('0')
  },
  beforeDestroy() {},
  methods: {
    // 上一步
    backRtwo() {
      this.$router.go(-1);
    },
    // 计算测评结果
    async calculationResult() {
      // 接收传过来的id值
      this.eId = this.$route.query.eId;
      let data = _qs.stringify({
        id: this.eId,
      });
      let { data: res } = await this.$axios.calculationResult(data);
      // console.log(res);
      if (res.code == 0) {
        // this.rcaoData = res.data;
        this.rcaovalue = res.value;
      } else if (res.code == 401) {
        this.$router.push("/login");
      }
    },
    // 根据状态查询测评结果
    async selectCalculation(val) {
      this.type = val;
      let data = _qs.stringify({
        type: val,
        eId: this.$route.query.eId,
      });
      let { data: res } = await this.$axios.selectCalculation(data);
      // console.log(res);
      if (res.code == 0) {
        this.rcaoData = res.data;
      } else if (res.code == 401) {
        this.$router.push("/login");
      }
    },
    // 获取当前年月日
    addDate() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
      };
      const newmonth = date.month > 10 ? date.month : "0" + date.month;
      const day = date.date > 10 ? date.date : "0" + date.date;
      let updateTime = date.year + "-" + newmonth + "-" + day;
      return updateTime;
    },
    // 点击导出测评结果
    async exportCalculationExcel() {
      let params = {
        type: this.type,
        id: this.eId,
      };
      let res = await this.$axios.exportCalculationExcel(params);
      // console.log(res);
      if (res.status == 200) {
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.status == 200) {
          let updateTime = this.addDate();
          let blob = new Blob([res.data], { type: "application/x-xls" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          //配置下载的文件名
          link.download = "测评结果" + updateTime + ".xls";
          link.click();
        }
      }
    },
    // 点击比价成本计算
    nonfee() {
      this.$router.push({
        path: "/nonfee",
        query: {
          eId: this.$route.query.eId,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.rcaonew {
  margin: 0 auto;
}

.rcaotit {
  text-align: center;
  margin-top: 44px;
  margin-bottom: 50px;
}

.rcaobtn {
  width: 170px;
  height: 60px;
  font-size: 16px;
  margin-left: 30px !important;
}

.rcaoMain {
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
}

.rcaotable {
  /deep/ .el-table__header-wrapper .el-table__header .has-gutter {
    color: black !important;
  }
}

.rcaorow {
  > div {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.rcaotwo {
  width: 85%;
  margin: 0 auto;
  > .el-col.el-col-11 {
    height: 60px;
  }
}

.rcaotop {
  width: 85%;
  margin: 0 auto;
  > .rcaoF {
    display: flex;
    flex-direction: row-reverse;
  }
}

.cesuanzhi {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
}

.rform {
  display: flex;
  flex-direction: row;
  margin-top: -20px;
  > .el-form-item {
    display: flex;
  }
  > .rfzt {
    margin-left: 15px;
    margin-bottom: 10px;
  }
  /deep/ .el-form-item__label {
    font-size: 16px !important;
    line-height: 50px;
  }
  /deep/ .el-input--suffix .el-input__inner {
    height: 50px;
  }
}
</style>
